import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const AdministratorRoleConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/administrator-role",
    component: React.lazy(() => import("./administrator-role")),
  },
];
