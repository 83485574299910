import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const InstitutionTypeConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/institution-type",
    component: React.lazy(() => import("./institution-type")),
  },
];
