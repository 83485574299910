import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const RegistrationTypesConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/registration-types",
    component: React.lazy(() => import("./registration-types")),
  },
];
